/*global jQuery:false */
var DSC = window.DSC ||{};

DSC.Config = $.extend(true, {
	// Globaler Debug-Parameter, welcher in DSC.Utils.js genutzt wird
	debug: true,
    breakpointLaptop:1645,
    breakpointMenu:1199,
    breakpointTablet:919,
    breakpointMobile:519,
    gutter:20,
    gutterLaptop:20,
    gutterTablet: 20,
    gutterMobile:20,

    // default config for fancybox
    fancybox: {
        // Enable infinite gallery navigation
        loop: true,
        // scrollbar is already forced to be visible always, see layout_mod.scss
        hideScrollbar: false,
        // show counter at the top left corner
        infobar: false,
        // which buttons to show ("toolbar area" on top right)
        buttons: [
            // "zoom",
            //"share",
            // "slideShow",
            //"fullScreen",
            //"download",
            // "thumbs",
            "close"
        ],
        // Open/close animation type
        animationEffect: "fade", // never use "zoom" as it causes issues for download center module (because trigger area != image area) and the shadow would appear to "jump" on inital open

        // hide controls after idleTime (in seconds), if set to "false" controls are always shown
        idleTime: false,

        // Should allow caption to overlap the content
        preventCaptionOverlap: true,

        // templates
        btnTpl: {
            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
                '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg>' +
                "</button>",
            // Arrows
            arrowLeft:
                '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                '<div><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><polyline class="stroke-color-main" points="65.244 186 134.756 100 65.244 14"/></svg></div>' +
                "</button>",
            arrowRight:
                '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                '<div><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><polyline class="stroke-color-main" points="65.244 186 134.756 100 65.244 14"/></svg></div>' +
                "</button>"
        }
    }

}, DSC.Config);