/*global jQuery:false */
var DSC = window.DSC || {};

DSC.Core = (function($){

	var _$html,
        _$b,
        _$w,
        _mobile,
        _to,
        isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

	function _init() {
        _$html = $('html');
		_$b = $('body');
        _$w = $(window);
        _mobile = DSC.Utils.detectVars.isTouchDevice;

        _$html.removeClass('no-js');

        if (_mobile === false) { 
            _$html.addClass('no-touch');
        } else {
        }

        // global onResizeEnd implementation, emits custom event type "_onResizeEnd" on window
        _$w.on('resize', function() {

            clearTimeout(_to);

            _to = setTimeout(function() {

                _$w.trigger('_onResizeEnd');

                // re-trigger IE11 polyfill for object-fit
                if(isIE11) {
                    _objectFitPolyfill();
                }

            }, 200);

        });

        // bind img.onLoad to re-trigger IE11 polyfill for object-fit
        if(isIE11) {

            $('picture img').on('load _ieObjectFitPolyfill', function() {
                _objectFitPolyfill();
            });

        }

        // init map(s)
        initMaps();

	}

	// IE11 objectFitPolyfill helper
	function _objectFitPolyfill() {
        if(typeof objectFitPolyfill === 'function') {
            objectFitPolyfill();
        }
    }

    function initMaps() {

        var maps = document.querySelectorAll('.js-map'),
            i=0;

        for(i; i < maps.length; i++) {

            setupMap(maps[i]);

        }

    }

    // setup map (using Leaflet with tiles from openstreetmap.org)
    function setupMap(mapNode) {

	    var conf = mapNode.dataset,
            map = new L.Map(mapNode),
            layer = new L.TileLayer(
                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                {
                    minZoom: 8,
                    maxZoom: 17, // WARNING: before increasing this value, please read section "bulk downloading" on this page: https://operations.osmfoundation.org/policies/tiles/
                    attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
                }
            ),
            markerIconSizes = conf.markerSize.split(','),
            markerIconSize = {
                h: markerIconSizes[1],
                w: markerIconSizes[0]
            },
            markerIcon = L.icon({
                iconUrl: conf.marker,
                iconSize: [markerIconSize.w, markerIconSize.h],
                iconAnchor: [markerIconSize.w * 0.5, markerIconSize.h] // alignment: center bottom
            }),
            lat = parseFloat(conf.lat),
            lon = parseFloat(conf.lon);

	    // center map view around lat/lon coord and set zoom
	    map.setView(
	        new L.LatLng(lat, lon),
            parseInt(conf.zoom) || 16
        );

	    // add tile layer to map
	    map.addLayer(layer);

	    // add marker to map
        L.marker([lat, lon], {
            icon: markerIcon,
            keyboard: false
        }).addTo(map);

        // add (panning) offset (map viewport center is now based on marker center)
        map.panBy(
            L.point(0, markerIconSize.h * -0.5),
            {
                animate: false
            }
        );

    }

	$(_init);
})(jQuery);