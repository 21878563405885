var DSC = window.DSC || {};

DSC.mAccordionGridCavalluna1 = (function ($) {
    var $w,
        $b,
        $accordionGridCavalluna1,
        $contentLeft,
        $contentLeftList,
        $contentLeftListItem,
        $contentRight,
        $contentRightList,
        $contentRightListItem,
        $accordions,
        viewport,
        viewportWidth;

    function init() {
        $w = $(window);
        $b = $('body');
        $accordionGridCavalluna1 = $b.find('.js-accordion-grid-cavalluna-1');

        $accordions = $accordionGridCavalluna1.find('.js-accordion');

        if($accordionGridCavalluna1.length > 0) {
            setHeight();

            var to;
            $w.on('resize', function () {
                closeAccordion();

                clearTimeout(to);
                to = setTimeout(function() {
                    $w.trigger('resizeEnd');
                }, 200);
            });

            $w.on('resizeEnd', function () { //ToDoFE replace with global _onResizeEnd?
                setHeight();
            });
        }

        function setHeight(){
            getViewportWidth();

            $accordionGridCavalluna1.each(function(){
                var $this = $(this);
                $contentLeft = $this.find('.js-accordion-grid-cavalluna-1-content-left');
                $contentLeftList = $contentLeft.find('.js-accordion-grid-cavalluna-1-accordion-list');
                $contentLeftListItem = $contentLeftList.children();
                $contentRight = $this.find('.js-accordion-grid-cavalluna-1-content-right');
                $contentRightList = $contentRight.find('.js-accordion-grid-cavalluna-1-accordion-list');
                $contentRightListItem = $contentRightList.children();

                for (var i=0; i < $contentLeftListItem.length; i++) {
                    var $currentLeftListItem = $($contentLeftListItem[i]),
                        $currentRightListItem = $($contentRightListItem[i]);

                    $currentLeftListItem.css({'min-height':''});
                    $currentRightListItem.css({'min-height':''});

                    var contentLeftListItemHeight = $currentLeftListItem.outerHeight(),
                        contentRightListItemHeight = $currentRightListItem.outerHeight();

                    if(viewportWidth > DSC.Config.breakpointTablet){
                        var largerHeight = (contentLeftListItemHeight > contentRightListItemHeight) ? contentLeftListItemHeight : contentRightListItemHeight;
                        $currentLeftListItem.css({'min-height':largerHeight});
                        $currentRightListItem.css({'min-height':largerHeight});
                    }
                }
            });
        }

        function closeAccordion() {
            $accordions.each(function () {
               var $this = $(this),
                   $thisContent = $this.find('.js-accordion-content');
                if($this.hasClass('js-open')) {
                    $thisContent.velocity('slideUp', {
                        duration:200,
                        begin: function() {
                            $this.removeClass('js-open');
                        }
                    });
                }
            });
        }

        function getViewportWidth() {
            viewport = DSC.Utils.getViewport();
            viewportWidth = viewport.width;
        }
    }


    $(init);
})(jQuery);
