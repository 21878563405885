/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mNewsletterCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function _init() {
        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-newsletter-cavalluna-1');

        if ($modules.length > 0){
            $modules.each(function () {
                var $this = $(this),
                    $thisCheckboxes = $this.find('.js-checkbox'),
                    $thisBackgroundItems = $this.find('.js-background-item');

                $thisCheckboxes.on('click', function(){
                    var $this = $(this),
                        thisIndex = $this.attr('data-index'),
                        $activeCheckboxes = $thisCheckboxes.not($this).filter(':checked'),
                        $thisMatchingBackgroundItem = $thisBackgroundItems.filter("[data-index='" + thisIndex + "']"),
                        thisCheckedState = $this.is(':checked');

                    if($activeCheckboxes.length === 1){
                        var activeCheckboxesIndex = $activeCheckboxes.attr('data-index'),
                            $activeCheckboxesMatchingBackgroundItem = $thisBackgroundItems.filter("[data-index='" + activeCheckboxesIndex + "']");

                        $activeCheckboxesMatchingBackgroundItem.addClass('js-active');
                    }

                    if(thisCheckedState && $activeCheckboxes.length === 0){
                        $thisMatchingBackgroundItem.addClass('js-active');
                    } else if (thisCheckedState && $activeCheckboxes.length > 0) {
                        $thisBackgroundItems.removeClass('js-active');
                    } else {
                        $thisMatchingBackgroundItem.removeClass('js-active');
                    }
                })
            })
        }

    }

    $(_init);
})(jQuery);
