/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mBackButtonCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function _init() {
        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-back-button-cavalluna-1');

        if ($modules.length > 0){
            $modules.each(function () {
                if (location.hostname == document.referrer.split('/')[2]) {
                    $(this).show();
                    $(this).on('click', function(){
                        window.history.back();
                    })
                }
            })
        }
    }

    $(_init);
})(jQuery);
