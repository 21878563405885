var DSC = window.DSC || {};

DSC.mShowplaceTeaserCavalluna1 = (function ($) {

    function init() {

        // bind "onResizeEnd"
        var to;
        $(window).on('resize', function() {

            clearTimeout(to);
            to = setTimeout(calcOffset, 200);

        });

        // do calculations
        calcOffset();

    }

    // measures text height and apply its value as bottom value on parent (and css takes care of animation based on set values)
    function calcOffset() {

        $('.js-showplace-teaser-cavalluna-1 .js-showplace-teaser-cavalluna-1-item-text').each(function() {

            $(this).closest('.js-showplace-teaser-cavalluna-1-item-content').css('bottom', $(this).outerHeight());

        });

    }


    $(init);
})(jQuery);
