var DSC = window.DSC || {};

DSC.Accordion = (function ($) {
    var _$w,
        _$b,
        _$accordions,
        _$accordionTrigger;

    function _init() {
        _$b = $('body');
        _$accordions = _$b.find('.js-accordion');
        _$accordionTrigger = _$accordions.find('.js-accordion-trigger');

        if(_$accordions.length>0) {
            //Event Listener
            _$accordionTrigger.on('click', _openCloseAccordion);

            _$accordions.each(function(){
                var _$this = $(this);
                var _dataOpenOnStart = _$this.data('open-on-start');

                if(_dataOpenOnStart === true) {
                    var _$accordionContent = _$this.find('.js-accordion-content');
                    _$accordionContent.velocity('slideDown', {
                        duration:0
                    });
                    _$this.addClass('js-open');
                }
            });

        }
    }

    //Event Handler
    function _openCloseAccordion() {
        var _$trigger = $(this);
        if(_$trigger.hasClass('svg-icon')) {
            _$trigger = _$trigger.parent('.m-accordion-info-cavalluna-1__headline');
        }
        var _$accordionWrapper = _$trigger.parent('.js-accordion');
        if(_$trigger.hasClass('js-accordion-content')) {
            var _$accordionContent = _$trigger;
        } else {
            var _$accordionContent = _$trigger.siblings('.js-accordion-content');
        }
        if(_$accordionWrapper.hasClass('js-open')) {
            _$accordionContent.velocity('slideUp', {
                duration:200,
                begin: function() {
                    _$accordionWrapper.removeClass('js-open');
                }
            });
        }
        else {
            _$accordionContent.velocity('slideDown', {
                duration:200,
                begin: function() {
                    _$accordionWrapper.addClass('js-open');
                }
            });

        }
    }

    $(_init);

})(jQuery);