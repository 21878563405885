/*global jQuery:false */
var DSC = window.DSC ||{};

DSC.Selectric = (function($) {

    var _$b,
        _$selects;

    function _init() {
        _$b = $('body');
        _$selects = _$b.find('.js-selectric');

        if(_$selects.length > 0) {
            _$selects.each(function(){
                var _$this = $(this);
                
                _initializeSelectric(_$this);
                
            });
        }

    }


    function _refresh() {
        _$selects.selectric('refresh');
    }
    
    function _initializeSelectric(select){
        var _selectricIcon = select.siblings('.js-selectric-icon').find('.svg-icon');

        select.selectric({
            arrowButtonMarkup: _selectricIcon,
            onBeforeOpen: function(element, selectric) {
                //Selectric sets the "selected" class on the selected dropdown-item on opening the dropdown
                //not after selecting the item. This means, that one can see the background-transition from the
                //last selected item to the current one flashing when the dropdown opens. To prevent that,
                //we turn off transitions beforeOpen by adding a class, and remove that class once the dropdown
                //is open. Thus, hover and selection transitions work when navigating inside the dropdown,
                //but the flashing on opening disappears
                selectric.elements.items.addClass('js-before-open');
            },
            onOpen: function(element, selectric) {
                selectric.elements.items.removeClass('js-before-open');
            },
            openOnFocus: false,
            multiple: {
                keepMenuOpen: false
            }
        });
    }


    $(_init);

    return {
        init:_init,
        initializeSelectric: _initializeSelectric,
        refresh: _refresh
    }

})(jQuery);