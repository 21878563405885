/*global jQuery:false */
var DSC = window.DSC ||{};

DSC.CombinedInputLabel = (function($) {

    var _$b,
        _$inputLabelWrapper,
        _$inputs,
        _$labels;

    function _init() {
        _$b = $('body');
        _$inputLabelWrapper = _$b.find('.js-combined-input-label');
        _$inputs = _$inputLabelWrapper.find('input, textarea');
        _$labels = _$inputLabelWrapper.find('label');


        _$inputs.on('focus', function(){
            var _$this = $(this),
                _$label = _$this.siblings('label');


            //LABEL-ANIMATION
            _$this.addClass('js-hide-placeholder');
            _$label.addClass('js-show-label');

            _$this.one('blur', function(){
                var value = _$this.val();
                if(value.length === 0) {
                    _$this.removeClass('js-hide-placeholder');
                    _$label.removeClass('js-show-label');
                }
            });
        });
    }

    $(_init);

    return {
        init: _init
    }


})(jQuery);