var DSC = window.DSC || {};

DSC.cVideo1 = (function ($) {
    var $b,
        $module;

    function init() {
        $b = $('body');
        $module = $b.find('.js-video-1');

        if($module.length > 0) {
            $module.each(function () {
                var $this = $(this),
                    $thisOverlay = $this.find('.js-video-1-overlay'),
                    thisVideo = $this.find('.js-video-1-video').get(0),
                    $thisYouTubeVideo = $this.find('.js-video-1-youtube'),
                    $thisVimeoVideo = $this.find('.js-video-1-vimeo'),
                    thisYouTubeVideoDataId = $thisYouTubeVideo.data('video-id');

                // standard video element
                if(thisVideo){
                    $thisOverlay.on('click', function () {
                        if (!$this.hasClass('js-play-video')){
                            $this.addClass('js-play-video');
                            thisVideo.play();
                        }
                    });
                }

                // youtube video
                if($thisYouTubeVideo.length){
                    function initYT() {
                        var player;
                        player = new YT.Player($thisYouTubeVideo.get(0), {
                            playerVars: {'color':'white', 'fs':0, 'playsinline':1, 'rel':0, 'showinfo':0, 'origin':location.origin, 'cc_load_policy':1},
                            videoId: thisYouTubeVideoDataId,
                            events: {
                                'onReady': function() {
                                    $thisOverlay.on('click', function () {
                                        if (!$this.hasClass('js-play-video')){
                                            $this.addClass('js-play-video');
                                            player.playVideo();
                                        }
                                    });
                                }
                            }
                        });
                    }

                    // test if flag was already set (and therefore event was already dispatched)
                    if(!window.isYouTubeIframeAPIReady) {
                        // flag was not set yet, therefore we register the event handler
                        $(window).on('youTubeIframeAPIReady', initYT);
                    } else {
                        // event was already dispatched, so simply call our init function
                        initYT();
                    }

                }

                // vimeo video
                if($thisVimeoVideo.length){
                    var player = new Vimeo.Player($thisVimeoVideo.get(0), {});
                    $thisOverlay.on('click', function () {
                        if (!$this.hasClass('js-play-video')){
                            $this.addClass('js-play-video');
                            player.play();
                        }
                    });
                }
            })
        }
    }
    $(init);
})(jQuery);
