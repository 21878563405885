/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mSliderSpecialCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function _init() {

        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-slider-special-cavalluna-1');

        $modules.each(function () {

            var $thisModule = $(this),
                $slider = $thisModule.find('.js-slider-special-track'),
                $goTo = $thisModule.find('.js-slider-special-go-to'),
                $goToItem = $goTo.find('.js-slider-special-go-to-item'),
                _$arrowLeft = $thisModule.find('.js-arrow-left'),
                _$arrowRight = $thisModule.find('.js-arrow-right');

            // bind event listeners before initializing slick
            $slider
                .on('init reInit', slickUpdate)
                .on('afterChange', function(event, slick, currentSlide){

                    // update custom slide dot-nav
                    $($goToItem[currentSlide]).addClass('js-active');
                    $goToItem.not($goToItem[currentSlide]).removeClass('js-active');

                })
                .slick({
                    arrows: false,
                    centerMode: true,
                    centerPadding: 0,
                    customPaging: function() {
                        return('<button type="button" data-role="none" role="button"></button>');
                    },
                    dots: false,
                    slidesToShow:1,
                    slidesToScroll:1,
                    adaptiveHeight: false,
                    speed: 300,
                    autoplay: !!$slider.data('autoplay'),
                    autoplaySpeed: $slider.data('autoplay')
                });

            // bind global custom onResizeEnd to force slick to repaint and emitting 'reInit' once it is finished
            $w.on('_onResizeEnd', function() {

                // this is causing an actual refresh, not using slick('reinit') due to some weird issues with slide order
                $slider.slick('setOption', {}, true); // this still causes slick to emit an 'reInit' event

            });

            if($goToItem.length > 1){

                // set active class on first dot nav item
                var $currentSlideOnInit = $slider.slick('slickCurrentSlide');
                $($goToItem[$currentSlideOnInit]).addClass('js-active');

            } else {

                // hide the special dot navigation if no panel or only one panel (=nothing to slide)
                $goToItem.hide();

            }

            _$arrowLeft.on('click', function() {
                $slider.slick('slickPrev');
            });

            _$arrowRight.on('click', function() {
                $slider.slick('slickNext');
            });

            $goToItem.on('click', function () {
                var $this = $(this),
                    $thisSlideData = $this.data('slide');

                if (!$this.hasClass('js-active')) {
                    $this.addClass('js-active');
                    $goToItem.not($this).removeClass('js-active');
                }
                $slider.slick('slickGoTo', $thisSlideData);
            });

        });

        function slickUpdate(ev, slick) {

            // toggle class if it contains only one panel
            $(this).toggleClass('slick-slider--is-single-slide', DSC.Utils.slickIsSingleSlide(slick));

        }

    }

    $(_init);

})(jQuery);


