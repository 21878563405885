/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mSliderHeroCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules,
        isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    function _init() {

        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-slider-hero-cavalluna-1');

        $modules.each(function () {

            var $thisModule = $(this),
                $slider = $thisModule.find('.js-slider-hero-track'),
                _$arrowLeft = $thisModule.find('.js-arrow-left'),
                _$arrowRight = $thisModule.find('.js-arrow-right');

            if(isIE11) {

                // even though we use a picture polyfill, we must replace the img src with first srcset src because
                // in this context the img actually uses an empty inline image (to ensure no image is loaded on mobile)
                $thisModule.find('picture').each(function() {
                    // replace mobile src with desktop src (first source contains desktop)
                    $(this).find('img').attr('src', $(this).find('source:eq(0)').attr('srcset'));
                });

            }

            $slider
                .on('init reInit', slickUpdate)
                .slick({
                    arrows: false,
                    centerMode: true,
                    centerPadding: 0,
                    customPaging: function() {
                        return '';
                    },
                    dots: true,
                    slidesToShow:1,
                    slidesToScroll:1,
                    adaptiveHeight: true,
                    speed: 300,
                    autoplay: !!$slider.data('autoplay'),
                    autoplaySpeed: $slider.data('autoplay')
                });

            // bind global custom onResizeEnd to force slick to repaint and emitting 'reInit' once it is finished
            $w.on('_onResizeEnd', function() {

                // this is causing an actual refresh, not using slick('reinit') due to some weird issues with slide order
                $slider.slick('setOption', {}, true); // this still causes slick to emit an 'reInit' event

            });

            _$arrowLeft.on('click', function() {
                $slider.slick('slickPrev');
            });

            _$arrowRight.on('click', function() {
                $slider.slick('slickNext');
            });

        });

        function slickUpdate(ev, slick) {

            // toggle class if it contains only one panel
            $(this).toggleClass('slick-slider--is-single-slide', DSC.Utils.slickIsSingleSlide(slick));

        }

    }

    $(_init);

})(jQuery);


