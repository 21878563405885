/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mSliderGalleryCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function _init() {

        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-slider-gallery-cavalluna-1');

        $modules.each(function (i) {
            var $thisModule = $(this),
                id = 'sgcava' + i, // construct an id for fancybox gallery
                $slider = $thisModule.find('.js-slider-gallery-track'),
                _$arrowLeft = $thisModule.find('.js-arrow-left'),
                _$arrowRight = $thisModule.find('.js-arrow-right');

            // set specific fancybox id on module
            $thisModule.attr('data-sg-id', id);

            // init fancybox with default options and set specific selector
            $().fancybox($.extend(
                {}, // Note: this empty obj is required to "clone" the merged options
                DSC.Config.fancybox, // default options

                // specific/additional options:
                {
                    selector: '.js-slider-gallery-cavalluna-1[data-sg-id="' + id + '"] .js-fancybox',
                    backFocus: false, // otherwise it causes conflicts with slick slider if set to true
                    // custom caption function, takes the figcaption html from slide item
                    caption: function(instance, item) {
                        return $(this).next('figcaption').html();
                    },
                    // toggle bg-color class based on current item data-attribute
                    beforeShow: function(instance) {

                        $('.fancybox-container').toggleClass('fancybox--inverted-bg', !!$slider.find('.js-fancybox').eq(instance.currIndex).data('lb-bg'));

                    }
                }
            ));

            $slider
                .on('init reInit', slickUpdate)
                .slick({
                    arrows: false,
                    centerPadding: 0,
                    customPaging: function() {
                        return('<button type="button" data-role="none" role="button"></button>');
                    },
                    dots: true,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    speed: 300,
                    autoplay: !!$slider.data('autoplay'),
                    autoplaySpeed: $slider.data('autoplay'),
                    responsive: [
                        {
                            breakpoint: DSC.Config.breakpointTablet + 1,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: DSC.Config.breakpointMobile + 1,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

            // bind global custom onResizeEnd to force slick to repaint and emitting 'reInit' once it is finished
            $w.on('_onResizeEnd', function() {

                // this is causing an actual refresh, not using slick('reinit') due to some weird issues with slide order
                $slider.slick('setOption', {}, true); // this still causes slick to emit an 'reInit' event

            });

            _$arrowLeft.on('click', function() {
                $slider.slick('slickPrev');
            });

            _$arrowRight.on('click', function() {
                $slider.slick('slickNext');
            });

        });

        function slickUpdate(ev, slick) {

            // toggle class if it contains only one panel
            $(this).toggleClass('slick-slider--is-single-slide', DSC.Utils.slickIsSingleSlide(slick));

        }

    }

    $(_init);

})(jQuery);