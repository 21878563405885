/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mSliderReviewCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function init() {

        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-slider-review-cavalluna-1');

        $modules.each(function(){
            var _$this = $(this),
                $slider = _$this.find('.js-slider-review-track'),
                _$arrowLeft = _$this.find('.js-arrow-left'),
                _$arrowRight = _$this.find('.js-arrow-right'),
                _$dots = _$this.find('.js-dots');

            $slider
                .on('init reInit', slickUpdate)
                .slick({
                    dots: true,
                    appendDots: _$dots,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows:false,
                    useTransform: false, //avoid flickering from last to first slide
                    autoplay: !!$slider.data('autoplay'),
                    autoplaySpeed: $slider.data('autoplay'),
                    rows: $slider.data('rows') ? $slider.data('rows') : 1,
                    responsive: [
                        {
                            breakpoint: DSC.Config.breakpointTablet + 1,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: DSC.Config.breakpointMobile + 1,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });

            // bind global custom onResizeEnd to force slick to repaint and emitting 'reInit' once it is finished
            $w.on('_onResizeEnd', function() {

                // this is causing an actual refresh, not using slick('reinit') due to some weird issues with slide order
                $slider.slick('setOption', {}, true); // this still causes slick to emit an 'reInit' event

            });

            _$arrowLeft.on('click', function() {
                $slider.slick('slickPrev');
            });

            _$arrowRight.on('click', function() {
                $slider.slick('slickNext');
            });
        });

        function slickUpdate(ev, slick) {

            // toggle class if it contains only one panel
            $(this).toggleClass('slick-slider--is-single-slide', DSC.Utils.slickIsSingleSlide(slick));

        }

    }

    $(init);
})(jQuery);
