/*global jQuery:false */
var DSC = window.DSC ||{};

DSC.Utils = (function($) {
	
	// Variablen zur Detektion von Events/Devices usw. a la modernizr.js
	var _detectVars = {
		transitionEvent: _whichTransitionEndEvent(),
		isTouchDevice: _isTouchDevice()
	};
	
	// Prüft welche Transition vorhanden ist
	function _whichTransitionEndEvent() {
		var t;
		var el = document.createElement('fakeelement');
		var transitions = {
			'transition':'transitionend',
			'OTransition':'oTransitionEnd',
			'MozTransition':'transitionend',
			'WebkitTransition':'webkitTransitionEnd'
		};

		for(t in transitions){
			if( el.style[t] !== undefined ) {
				return transitions[t];
			}
		}
	}
	
	// Funktion um herauszufinden ob es ein Touch-Device ist
	function _isTouchDevice() {
		var msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture;
		return ( ( 'ontouchstart' in window ) || (window.DocumentTouch && document instanceof DocumentTouch )) ? true : false;// MSFT specific.
	}
	
	// Gibt den aktuellen Viewport zurück
	// natives javascript, da jQuery die falschen Werte mit $(window).innerHeight() usw. zurückgibt
	// 
	// siehe https://andylangton.co.uk/blog/development/get-viewportwindow-size-width-and-height-javascript
	function _getViewport() {
		var e = window, 
			a = 'inner';
		if(!('innerWidth' in window)) {
			a = 'client';
			e = document.documentElement || document.body;
		}
		return {width: e[a+'Width'], height: e[a+'Height']};
	}
	
	// Gekapselte Log-Funktion die global über die Konfiguration gesteuert wird.
	// Damit soll verhindert werden das console.log() genutzt wird und event. vergessen wird
	//
	function _log() {
		if (DSC.Config.debug && window.console && window.console.log) {
			window.console.log.apply(window.console, arguments);
		}
	}

	// generic function to determine for slick if it is a single slide
	function _slickIsSingleSlide(slick) {

		// Unfortunately slick is unable to provide a way to detect if there is nothing to slide actually.
		// To make matters even worse slick does not differentiate between panel and panel items - everything is a "slide"
		// Therefore we multiply "slides" (=slideCount) with "slide" width (=slideWidth) and substract slide viewport width (=$slider.width())
		// Values less or equal to zero indicate that there is nothing to slide but due to slicks internal rounding for
		// slideWidth we must compare against values smaller or equal than 1

		return (slick.slideCount * slick.slideWidth) - slick.$slider.width() <= 1;

	}
	
	// Diese Funktionen werden nach außen zurückgegeben
	return {
		log: _log,
		whichTransitionEndEvent: _whichTransitionEndEvent,
		getViewport: _getViewport,
		// Objekt mit Angaben zu Touch-Device und Transitionevent
		detectVars: _detectVars,
		slickIsSingleSlide: _slickIsSingleSlide
	};

})(jQuery);