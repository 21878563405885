var DSC = window.DSC || {};

DSC.mDownloadCenterCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules,
        viewport,
        viewportWidth;

    function init() {
        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-download-center-cavalluna-1');


        if($modules.length > 0) {
            $modules.each(function (i) {
                var $this = $(this),
                    $blocks = $this.find('.js-download-center-cavalluna-1-block'),
                    $thisOverlayTrigger = $this.find('.js-overlay-trigger'),
                    $thisCloseTrigger = $this.find('.js-close-trigger'),
                    $thisRegionTrigger = $this.find('.js-region-trigger'),
                    $thisRegions = $this.find('.js-region'),
                    $thisOverlayItems = $this.find('.js-overlay-item');

                // init fancybox for each block
                $blocks.each(function(j){

                    var id = 'dccava' + i + '_' + j; // construct an id for fancybox gallery

                    // set specific fancybox id on module items
                    $(this).attr('data-sg-id', id);

                    // init fancybox with default options and set specific selector
                    $().fancybox($.extend(
                        {}, // Note: this empty obj is required to "clone" the merged options
                        DSC.Config.fancybox, // default options

                        // specific/additional options:
                        {
                            selector: '.js-download-center-cavalluna-1-block[data-sg-id="' + id + '"] .js-fancybox'
                        }
                    ));

                });

                $thisRegions.on('click', function () {
                    setActiveOverlayContent($(this), $thisRegions, $thisOverlayItems);
                });

                $thisRegionTrigger.on('click', function () {
                   var $this = $(this);
                   getViewportWidth();

                   if (viewportWidth <= DSC.Config.breakpointTablet) {
                       if (!$this.parent().hasClass('js-select-open')){
                           $this.parent().addClass('js-select-open');
                           $this.siblings('ul').velocity('slideDown', {
                               duration:200
                           });
                       } else {
                           $this.parent().removeClass('js-select-open');
                           $this.siblings('ul').velocity('slideUp', {
                               duration:200
                           });
                       }
                   }
                });

                $thisOverlayTrigger.on('click', function() {
                    toggleOverlay($this);
                }).children().on('click', function(e){e.stopPropagation()});

                $thisCloseTrigger.on('click', function() {
                    toggleOverlay($this);
                });
            })
        }

        function toggleOverlay(module) {
            if (!module.hasClass('js-overlay-open')) {
                module.addClass('js-overlay-open');
            } else {
                module.removeClass('js-overlay-open');
            }
        }

        function setActiveOverlayContent(activeRegion, regions, overlayItems) {
            var regionIndex = activeRegion.index(),
                $activeOverlayItem = $(overlayItems.get(regionIndex));

            if(!activeRegion.hasClass('js-active')){
                regions.not(activeRegion).removeClass('js-active');
                overlayItems.not($activeOverlayItem).removeClass('js-active');
                activeRegion.addClass('js-active');
                $activeOverlayItem.addClass('js-active');
            }
        }

        function getViewportWidth() {
            viewport = DSC.Utils.getViewport();
            viewportWidth = viewport.width;
        }
    }


    $(init);
})(jQuery);
