/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mHeroCavalluna1 = (function ($) {
    var $w,
        $b,
        $videos,
        $videoOverlayImages;

    function _init() {
        $w = $(window);
        $b = $('body');
        $videos = $b.find('.js-hero-video-cavalluna-1');
        $videoOverlayImages = $b.find('.js-hero-picture-cavalluna-1');

        function updateMedia() {

            $videos.each(function() {

                var $t = $(this),
                    $video = $t.find('video'),
                    $currentSources = $video.find('.js-hero-video-source-cavalluna-1').filter(function(a,b){
                        return $(b).css('display') !== 'none';
                    });

                // check if currentSource has been changed
                if(!$($t.data('currentSources')).is($currentSources)) {

                    // remove any previously existing source
                    $video.find('source').remove();

                    $currentSources.each(function() {
                        var $source = $(this);

                        $video.append($('<source>').attr({
                            src: $source.data('src'),
                            type: $source.data('type')
                        }));
                    });

                }

                // store currentSource
                $t.data('currentSources', $currentSources);

            });

            $videoOverlayImages.each(function() {

                var $t = $(this),
                    $picture = $t.find('picture'),
                    $currentSources = $picture.find('.js-hero-picture-source-cavalluna-1').filter(function(a,b){
                        return $(b).css('display') !== 'none';
                    });

                // check if currentSource has been changed
                if(!$($t.data('currentSources')).is($currentSources)) {

                    // remove any previously existing source
                    $picture.find('img').remove();

                    $currentSources.each(function() {
                        var $source = $(this);

                        $picture.append($('<img>').attr({
                            src: $source.data('src')
                        }));
                    });

                }

                // store currentSource
                $t.data('currentSources', $currentSources);

            });

        }

        updateMedia();

        var _to;
        $w.on('resize', function() {
            clearTimeout(_to);
            _to = setTimeout(updateMedia, 200);
        });

    }

    $(_init);
})(jQuery);
