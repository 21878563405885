/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mTicketsCavalluna1 = (function ($) {
    var $w,
        $b,
        $tabModules;

    function init() {

        $w = $(window);
        $b = $('body');
        $tabModules = $b.find('.js-tickets-tabs-cavalluna-1');

        $tabModules.each(function(){
            var $module = $(this),
                $triggers = $module.find('.js-tickets-tabs-cavalluna-1-trigger'),
                $panels = $module.find('.js-tickets-tabs-cavalluna-1-panel');

            $triggers.on('click', function() {

                var $trigger = $(this),
                    index = $trigger.prevAll().length;

                // toggle states/visibility of triggers/panels
                $triggers.add($panels).removeClass('is-active');
                $trigger.add($panels.eq(index)).addClass('is-active');

            });
        });

    }

    $(init);
})(jQuery);
