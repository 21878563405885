var DSC = window.DSC || {};

DSC.cPagebrowser1 = (function ($) {
    var $w,
        $b,
        $components,
        config;

    function init() {
        $w = $(window);
        $b = $('body');
        $components = $b.find('.js-pagebrowser-1');

        if($components.length > 0) {
            $components.each(function(){
                var _$this = $(this);
                showHidePageNumbers(_$this);
            });
        }

        $w.on('resize', function(){
            $components.each(function(){
                var _$this = $(this);
                showHidePageNumbers(_$this);
            });
        });

    }


    function showHidePageNumbers($component) {
        var _viewport = DSC.Utils.getViewport(),
            _viewportWidth = _viewport.width,
            $pages = $component.find('.js-number'),
            pagesLength = $pages.length,
            $activePage = $pages.filter('.js-active'),
            activePageIndex = $pages.index($activePage);

        if($activePage.length === 0) {
            activePageIndex = 0;
        }

        if(_viewportWidth < DSC.Config.breakpointTablet && _viewportWidth >= DSC.Config.breakpointMobile) {
            $pages.css('display','');

            for(var i = activePageIndex + 3; i < pagesLength; i++) {
                $pages.eq(i).css('display', 'none');
            }
            for(var i = activePageIndex - 3; i >= 0; i--) {
                $pages.eq(i).css('display', 'none');
            }

        }
        else if (_viewportWidth < DSC.Config.breakpointMobile) {
            $pages.css('display','');
            for(var i = activePageIndex + 2; i < pagesLength; i++) {
                $pages.eq(i).css('display', 'none');
            }
            for(var i = activePageIndex - 2; i >= 0; i--) {
                $pages.eq(i).css('display', 'none');
            }
        }
        else {
            $pages.css('display','');
        }

    }


    $(init);
})(jQuery);
