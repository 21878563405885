var DSC = window.DSC || {};

DSC.cTooltip1 = (function ($) {
    var $w,
        $b,
        $tooltip,
        confTooltip,
        handlerType = window.navigator.msPointerEnabled ? 'msMobile' : 'default'; // Adapted from: https://blogs.windows.com/buildingapps/2012/11/15/adapting-your-webkit-optimized-site-for-internet-explorer-10/#step4

    function init() {
        $w = $(window);
        $b = $('body');
        $tooltip = $b.find('.js-tooltip-1');
        confTooltip = {
            default: {
                triggerOpen: {
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    mouseleave: true,
                    tap: true
                }
            },
            msMobile: {
                triggerOpen: {
                    click: true//,tap: true
                },
                triggerClose: {
                    click: true//,tap: true
                }
            }
        };
        if($tooltip.length > 0) {

            $tooltip.each(function () {
                var $this = $(this);

                $this.tooltipster({
                    functionInit: function(instance, helper){
                        var content = $this.next('.c-tooltip-1__text').html();
                        instance.content(content);
                    },
                    functionReady: function(instance, helper){
                        $this.next('.c-tooltip-1__text').attr('aria-hidden', false);
                    },
                    functionAfter: function(instance, helper){
                        $this.next('.c-tooltip-1__text').attr('aria-hidden', true);
                    },
                    maxWidth: 300,
                    trigger: 'custom',
                    triggerOpen: confTooltip[handlerType].triggerOpen,
                    triggerClose: confTooltip[handlerType].triggerClose
                });
                $this
                    .focus(function(){
                        $(this).tooltipster('open');
                    })
                    .blur(function(){
                        $(this).tooltipster('close');
                    });

            });


        }


    }
    $(init);
})(jQuery);
