var DSC = window.DSC || {};

DSC.cBackToTop1 = (function ($) {
    var $w,
        $b,
        $modules;

    function init() {
        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-back-to-top-1');
        
        if($modules.length > 0) {
            $modules.on('click', function () {
                $b.velocity("scroll", { offset: "0"});
            })
        }

        $w.on('scroll', function(){
            var scrollPositon = $w.scrollTop(),
                offset = 100;

            if (scrollPositon > offset) {
                $modules.addClass('js-scrolled');
            } else {
                $modules.removeClass('js-scrolled');
            }
        });
    }

    $(init);
})(jQuery);
