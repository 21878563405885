/*global jQuery:false */
var DSC = window.DSC || {};

DSC.cRteTable = (function($){

    var $b,
        $w,
        $rteTableWrapper;

    function _init() {
        $b = $('body');
        $w = $(window);
        $rteTableWrapper = $b.find('.js-rte-table');

        $rteTableWrapper.each(function () {
            var $thisWrapper = $(this),
                $thisWrapperContent = $thisWrapper.find('.l-wrapper'),
                $thisTable = $thisWrapper.find('table'),
                $thisTableCaption = "",
                $thisTableHeadItems = $.map( $thisTable.find('thead tr th'), function( e, i ) {
                    return ('<div class="m-responsive-table__field">'+ e.innerHTML+'</div>' );
                }).join(' '),
                thisTableHeadItemsTitles =  $.map( $thisTable.find('thead tr th'), function( e, i ) {
                    return (e.innerHTML);
                }),
                $thisTableFootItems = $.map( $thisTable.find('tfoot tr td'), function( e, i ) {
                    if (thisTableHeadItemsTitles[i]){
                        return ('<div class="m-responsive-table__field" data-column-definition="'+ thisTableHeadItemsTitles[i] +'">'+ e.innerHTML+'</div>' );
                    } else {
                        return ('<div class="m-responsive-table__field m-responsive-table__field--no-title">'+ e.innerHTML+'</div>' );
                    }
                }).join(' '),
                $thisTableRows = $thisTable.find('tbody tr'),
                thisTableRowsMarkup = [];

            function renderTableHead(){
                if ($thisTableHeadItems.length){
                    return (
                        '<div class="m-responsive-table__row m-responsive-table__row--header">' +
                            $thisTableHeadItems +
                        '</div>'
                    )
                } else {
                    return ''
                }
            }

            function renderTableCaption(){
                if ($thisTable.find('caption')[0] !== undefined){
                    $thisTableCaption = $thisTable.find('caption')[0].innerHTML;
                    return (
                        '<div class="m-responsive-table__caption">' +
                            $thisTableCaption +
                        '</div>'
                    )
                } else {
                    return ''
                }
            }

            function renderTableFoot(){
                if ($thisTableFootItems.length){
                    return (
                        '<div class="m-responsive-table__row m-responsive-table__row--foot">' +
                            $thisTableFootItems +
                        '</div>'
                    )
                } else {
                    return ''
                }
            }

            $thisTableRows.each(function () {
                var $thisRow = $(this);
                var $thisTableRowCells = $.map( $thisRow.find('td'), function( e, i ) {
                    if (thisTableHeadItemsTitles[i]){
                        return ('<div class="m-responsive-table__field" data-column-definition="'+ thisTableHeadItemsTitles[i] +'">'+ e.innerHTML+'</div>' );
                    } else {
                        return ('<div class="m-responsive-table__field m-responsive-table__field--no-title">'+ e.innerHTML+'</div>' );
                    }
                }).join(' ');
                thisTableRowsMarkup.push(
                    '<div class="m-responsive-table__row">' +
                        $thisTableRowCells +
                    '</div>'
                );
            });
            thisTableRowsMarkup = thisTableRowsMarkup.join(' ');

            $thisWrapperContent.append(
                '<div class="m-responsive-table__scroll-wrapper">' +
                    '<div class="m-responsive-table__inner">' +
                        renderTableHead() +
                        thisTableRowsMarkup +
                        renderTableFoot() +
                    '</div>' +
                '</div>' +
                renderTableCaption()
            );
            $thisWrapper.addClass('m-responsive-table');
            $thisTable.css({'display':'none','visibility':'hidden'});
        });
    }


    $(_init);
})(jQuery);