/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mVideoTilesCavalluna1 = (function ($) {
    var $w,
        $b,
        $modules;

    function _init() {
        $w = $(window);
        $b = $('body');
        $modules = $b.find('.js-video-tiles-cavalluna-1');

        $modules.each(function (i) {
            var $thisModule = $(this),
                id = 'vtcava' + i; // construct an id for fancybox gallery

            // set specific fancybox id on module
            $thisModule.attr('data-vt-id', id);

            // init fancybox with default options and set specific selector
            $().fancybox($.extend(
                {}, // Note: this empty obj is required to "clone" the merged options
                DSC.Config.fancybox, // default options

                // specific/additional options:
                {
                    type: "iframe",
                    selector: '.js-video-tiles-cavalluna-1[data-vt-id="' + id + '"] .js-fancybox',
                    backFocus: false,
                    baseClass: 'm-video-tiles-cavalluna-1--fancybox',
                    arrows: false,
                }
            ));

        });

        if ($(window).width() > 960) {
            $(".m-video-tiles-cavalluna-1__item-inner").hover( playVideo, pauseVideo );
            //sets the audiobar with every page reload to zero, because video is default muted
            $(".m-video-tiles-cavalluna-1__audiobar-control-input").val(0);
        }


        function playVideo(e) {
            $('.m-video-tiles-cavalluna-1__video', this).get(0).play();
            $('.m-video-tiles-cavalluna-1__video', this).addClass('play');
            //Timebar change width on hover
            const videotimebar = document.querySelector(".m-video-tiles-cavalluna-1__video.play");
            $(videotimebar).on('timeupdate', function(){
                var percentage = ( videotimebar.currentTime / videotimebar.duration ) * 100;
                var timepercentage = $(this).parent().parent().find('.m-video-tiles-cavalluna-1__preview-timebar span');
                timepercentage.css("width", percentage+"%");
            });

            //audio control - volume change
            //hebt das muting auf
            $('.m-video-tiles-cavalluna-1__video').prop('muted', false);
            //erstellt eine ID für eine const um gesondert beim hover das Inputfeld der Audiobar anzusprechen
            $(".m-video-tiles-cavalluna-1__audiobar-control-input", this).attr('id', 'volume-control');
            const volumeControl = document.getElementById("volume-control");
            //erstellt eine ID für eine var um gesondert beim hover das audio des Videos anzusprechen
            $(".m-video-tiles-cavalluna-1__video", this).attr('id', 'audioin');
            var audioin = document.getElementById("audioin");
            //wenn sich ein input ändert, ändern sich alle anderen Inputfelder mit der value mit
            $("input[type=range]").change(function() {
                $('video').prop('volume',$(this).val()/100);
                $('input').val($(this).val());
            });

            var video = document.querySelectorAll(".m-video-tiles-cavalluna-1__video");
            const input = document.querySelectorAll(".m-video-tiles-cavalluna-1__audiobar-control-input");

            //var video = document.querySelector("video");
            //const input = document.querySelector("input");

            $("#volume-control").on("input", function(){
                audioin.volume = volumeControl.value * 0.01;
                if( volumeControl.value == 0) {
                    $(".m-video-tiles-cavalluna-1__audiobar .audiomutedicon").show();
                    $(".m-video-tiles-cavalluna-1__audiobar .audionotmutedicon").hide();
                } else {
                    $(".m-video-tiles-cavalluna-1__audiobar .audiomutedicon").hide();
                    $(".m-video-tiles-cavalluna-1__audiobar .audionotmutedicon").show();
                }
                $("input").each(function() {
                    $(this).on("input", function(){
                        $(this).volume = $(this).value * 0.01;
                    });
                });
            });

        }
        function pauseVideo(e) {
            $('.m-video-tiles-cavalluna-1__video', this).get(0).pause();
            $('.m-video-tiles-cavalluna-1__video', this).get(0).currentTime = 0;
            $(".m-video-tiles-cavalluna-1__video", this).removeClass('play');
            $(".m-video-tiles-cavalluna-1__audiobar-control-input", this).attr('id', '');
            $(".m-video-tiles-cavalluna-1__video", this).attr('id', '');
        }

    }
    $(_init);
})(jQuery);