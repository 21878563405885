var DSC = window.DSC || {};

DSC.mTabCavalluna1 = (function ($) {
    var $w,
        $b,
        $tabCavalluna1;

    function init() {
        $w = $(window);
        $b = $('body');
        $tabCavalluna1 = $b.find('.js-tab-cavalluna-1');

        // bind mobile dropdown trigger
        $tabCavalluna1.each(function(){
            var $thisModule = $(this),
                $thisModuleToggle = $thisModule.find('.js-tab-cavalluna-1-mobile-toggle');

            $thisModuleToggle.on('click', function () {
                // always toggle class because state is only visible on mobile viewports anyway
                $(this).toggleClass('js-open');
            });

        });
    }


    $(init);
})(jQuery);
