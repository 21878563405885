/*global jQuery:false */
var DSC = window.DSC || {};

DSC.mHeroPortalCavalluna1 = (function ($) {

    function _init() {
        var $module = $('.js-hero-portal-select:not(.js-initialized)').addClass('js-initialized'),
            $toggles = $module.find('.js-hero-portal-selector');

        $toggles.on('click', function(e) {

            var $item = $(this).closest('.js-hero-portal-selector-item'),
                isActive = $item.hasClass('is-active');

            if (!isActive) {

                // toggles state class(es)
                $toggles.closest('.js-hero-portal-selector-item.is-active').removeClass('is-active');
                $item.addClass('is-active');

                // only has an effect when narrow viewport
                $module.removeClass('is-open');

            } else {

                // prevent click on already selected item
                e.preventDefault();

                // only has an effect when narrow viewport
                $module.toggleClass('is-open');

            }

        });
    }
    $(_init);
})(jQuery);