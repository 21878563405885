var DSC = window.DSC || {};

DSC.mHeaderCavalluna1 = (function ($) {
    var $html,
        $w,
        $b,
        $header,
        headerContentWidth,
        $logo,
        $social,
        $languageSwitch,
        $navItem,
        $navItemNoSub,
        $navItemWrapper,
        $navItemTrigger,
        $ticket,
        $ticketTrigger,
        $mobileNavTrigger,
        $viewport,
        viewportWidth,
        viewportHeight,
        useMenuHover = true, // set this to false to disable mouseenter/-leave behaviour
        isTouch = false; // (pro-active) state class, only used when useMenuHover is enabled (Note: don't change its initial value!)

    function init() {
        $w = $(window);
        $b = $('body');
        $html = $('html');
        $header = $b.find('.js-header-cavalluna-1');
        $logo = $header.find('.js-logo');
        $social = $header.find('.js-social');
        $languageSwitch = $header.find('.js-language-switch');
        $navItem = $header.find('.js-nav-item:not(.js-nav-item--no-sub)');
        $navItemNoSub = $header.find('.js-nav-item.js-nav-item--no-sub');
        $navItemWrapper = $header.find('.js-nav-item-wrapper');
        $navItemTrigger = $header.find('.js-nav-item-trigger');
        $mobileNavTrigger = $header.find('.js-mobile-nav-trigger');
        $ticket = $header.find('.js-ticket');
        $ticketTrigger = $header.find('.js-ticket-trigger');

        if($header.length > 0) {
            $languageSwitch.on('click', function () {
                var $this = $(this);
                if (!$this.hasClass('js-open')){
                    $this.addClass('js-open')
                } else {
                    $this.removeClass('js-open')
                }
            });
            // toggle subnav on click
            $navItemTrigger.on('click', function () {
                var $this = $(this),
                    $thisParentNavItem = $this.parent($navItem);
                if (!$thisParentNavItem.hasClass('js-open')){
                    $navItem.not($thisParentNavItem).removeClass('js-open');
                    $thisParentNavItem.addClass('js-open');
                    $b.trigger('navOpen');
                } else {
                    $thisParentNavItem.removeClass('js-open');
                }
            });
            // open ticket fly-out menu on click
            $ticketTrigger.on('click', function () {
                var $this = $(this),
                    $thisParentTicket = $this.parent($ticket);
                if (!$thisParentTicket.hasClass('js-open')){
                    $thisParentTicket.addClass('js-open');
                    $b.trigger('ticketOpen');
                } else {
                    $thisParentTicket.removeClass('js-open');
                }
            });
            // toggle burger menu on click
            $mobileNavTrigger.on('click', function () {
                var $this = $(this);
                if (!$this.hasClass('js-open')){
                    $this.addClass('js-open');
                    $header.addClass('js-nav-open');
                    $b.trigger('mobileNavOpen');
                } else {
                    $this.removeClass('js-open');
                    $header.removeClass('js-nav-open');
                    $navItem.removeClass('js-open');
                }
            });

            // if enabled we bind mouseenter/-leave events to open/close sub nav without "clicking"
            if(useMenuHover) {

                // open subnav on mouseenter
                $navItemTrigger.on('mouseenter', function () {
                    // only use this behaviour when it is not a touch device
                    if(!isTouch) {
                        var $this = $(this),
                            $thisParentNavItem = $this.parent($navItem);
                        if (!$thisParentNavItem.hasClass('js-open')){
                            $navItem.not($thisParentNavItem).removeClass('js-open');
                            $thisParentNavItem.addClass('js-open');
                            $b.trigger('navOpen');
                        }
                    }
                });
                // set isTouch state, only needed to differentiate between touch and mouse
                $navItemTrigger.on('touchstart mouseenter', function(e) {
                    // Note: this works because event chain is touchstart > mouseenter
                    // therefore in case of a touch device this func is called first (touchstart) then above function (mouseenter) and finally this func again (mouseenter)
                    isTouch = e.type === 'touchstart';
                });

                // close subnav on mouseleave
                $navItemWrapper.on('mouseleave', function(e) {
                    $navItem.removeClass('js-open');
                });
                // close subnav on mouseenter on a nav item with no sub nav
                $navItemNoSub.on('mouseenter', function(e) {
                    $navItem.removeClass('js-open');
                });

            }

            // bind states
            $b.on('navOpen', function () {
                $ticket.removeClass('js-open');
            });
            $b.on('mobileNavOpen', function () {
                $ticket.removeClass('js-open');
            });
            $b.on('ticketOpen', function () {
                $navItem.removeClass('js-open');
                $mobileNavTrigger.removeClass('js-open');
                $header.removeClass('js-nav-open');
            });

            // bind outside click to close nav
            $html.on('click', function (e) {
                var $this = $(e.target);
                _updateViewport();
                if(viewportWidth > DSC.Config.breakpointMenu && !$this.is($header) && !$this.isChildOf($header)){
                    if($ticket.hasClass('js-open')) {
                        $ticket.removeClass('js-open');
                    }
                    if($navItem.hasClass('js-open')) {
                        $navItem.removeClass('js-open');
                    }
                } else if(viewportWidth <= DSC.Config.breakpointMenu && !$this.is($header) && !$this.isChildOf($header)) {
                    if($ticket.hasClass('js-open')) {
                        $ticket.removeClass('js-open');
                    }
                    if($mobileNavTrigger.hasClass('js-open')) {
                        $mobileNavTrigger.removeClass('js-open');
                        $header.removeClass('js-nav-open');
                        $navItem.removeClass('js-open');
                    }
                }
            });

            setBurgerNav();
            $w.on('_onResizeEnd', setBurgerNav);

            function setBurgerNav() {
                $header.removeClass('js-burger-nav');

                var logoWidth = $logo.outerWidth(true),
                    navWidth = $navItemWrapper.outerWidth(),
                    socialWidth = $social.outerWidth(),
                    languageWidth = $languageSwitch.outerWidth(true),
                    ticketWidth = $ticket.outerWidth();

                headerContentWidth = logoWidth+navWidth+socialWidth+languageWidth+ticketWidth;

                if(!$header.hasClass('js-burger-nav') && $header.outerWidth() <= headerContentWidth){
                    $header.addClass('js-burger-nav')
                } else if ($header.hasClass('js-burger-nav') && $header.outerWidth() > headerContentWidth) {
                    $header.removeClass('js-burger-nav')
                }
            }
        }
    }

    function _updateViewport() {
        $viewport = DSC.Utils.getViewport();
        viewportWidth = $viewport.width;
        viewportHeight = $viewport.height;
    }

    $(init);
})(jQuery);
